import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/pages.css"
export default function History() {
    return (
    <>
        <h1>History</h1>
        <h2>10.12.2019:</h2>
        <div className="mittigblocksatz">Nachdem im Sommer die ersten Raptexte geschrieben wurden, war den beiden Gründungsmitgliedern Messe687 und YoungPfand
        längst klar, dass demnächst ein neues Deutschraplabel gegründet wird. Da die beiden Rapbrüder zusammen im Gässeläcker aufgewachsen sind, war der
        Name "Gässeläcker Musik" kein großer Zufall. Am 10.12.2019 wurde neben der Labelgründung auch direkt der Youtube Kanal erstellt. Richtige Songs
        wurden bis dahin noch nicht aufgenommen. Zwei vierzig Sekunden lange Parts konnten die Jungs aber schon vorzeigen. Veröffentlicht wurden diese
        Parts jedoch nie.</div>
        <h2>17.01.2021:</h2>
        <div className="mittigblocksatz">Über ein Jahr Pause und noch keine Songs? Aus verschiedenen privaten Gründen mussten die beiden Rapper eine längere Pause
        einlegen. Doch als einer ihrer engsten Kollegen am 15.01. seinen Geburtstag feierte und für den Sommer eine Gartenparty ankündigte, kam die Idee für
        ihren ersten Song. Auf den legendären "Guter Tag" Beat von Young Alpha wollten sie einen Geburtstagssong schreiben. Neben Tipico und Pfandsuche konnten die 
        Jungs endlich mal ihrer dritten Leidenschaft nachgehen - rappen.</div>
        <h2>15.05.2021:</h2>
        <div className="mittigblocksatz">Während fleißig am ersten Song gearbeitet wurde, kam YoungPfand eine Idee. Er nahm kurzerhand über Nacht den
        "Pfandsong" auf. Der Song wurde jedoch nie veröffentlicht, da er nur für besondere Events gedacht war. Findet man eine große Menge Pfand oder geht beim Pokern
        All-In, hat man eine große Wahrscheinlichkeit den "Pfandsong" zu hören.</div>
        <h2>04.07.2021:</h2>
        <div className="mittigblocksatz">Studiozeit. Der Song wird endlich vom Papier aufs Ohr gebracht. Da die Gartenparty nichtmehr weit entfernt ist, sind die Jungs jetzt gefordert.
        Es gelingt ihnen tatsächlich, den Song an einem Tag aufzunehmen. Mit Rapmaker und Shotcut standen zwar keine besonders guten Mittel zur Verfügung,
        jedoch wurde so viel Liebe in den Text gesteckt, dass es sich einfach nur krass anhörte.</div>
        <h2>11.07.2021:</h2>
        <div className="mittigblocksatz">Jeder gute Song braucht auch ein gutes Cover. Am Abend davor sind zwei maskierte Männer losgezogen und haben in
        einem Tunnel eine große, grüne 21 gesprayt. Ob es sich dabei um die Rapper gehandelt hat, wissen wir bis heute nicht. Die Chance vor der 21 zu posieren
        wollten sie sich trotzdem nicht entgehen lassen. Nach einem längeren Fotoshooting entstand das perfekt Cover. Der Song wurde abends noch kurz 
        auf CD gebrannt und alles war fertig. Diese CD ist bis heute die einzige veröffentlichte physische CD.</div>
        <h2>18.07.2021:</h2>
        <div className="mittigblocksatz">Release. Der erste Song wurde erfolgreich veröffentlicht und kam gut an. Der Grundstein für eine erfolgreiche Karriere wurde gelegt.</div>
        <h2>23.07.2021:</h2>
        <div className="mittigblocksatz">Jetzt geht es Schlag auf Schlag. Nichtmal eine Woche nach dem letzten Release starten die Arbeiten für den nächsten Song.
        Während der HSV 3:1 auf Schalke gewinnt und den Jungs durch eine Tipicowette einen 10er bringt, wird innerhalb von drei Stunden der nächste Hit produziert.
        Da Farid Bang ein großes Vorbild für beide Rapper ist, musste einer seiner Songs dran glauben: "Zieh den Rucksack aus". Da der Song von beiden auf
        Dauerschleife gepumpt wurde, war es ein leichtes Spiel, den Text umzuschreiben. Abends stürmen die Jungs noch auf eine Hausparty und kündigen dort offiziell ihren zweiten Song an.</div>
        <h2>06.08.2021:</h2>
        <div className="mittigblocksatz">Die Jungs sind zurück im Studio. Der Song kann endlich aufgenommen werden und wird auch schnell fertig. Zum Release sind allerdings noch ein paar Wochen.
        </div>
        <h2>03.09.2021:</h2>
        <div className="mittigblocksatz">Die Zeit wird bestens genutzt. Diesmal soll es paralell zum Song auch noch ein Musikvideo geben. Am besagten Tag
        klingelt deshalb der Wecker schon um 5 Uhr morgens. Perfekt zum Sonnenaufgang taucht der Benz auf dem Feld nähe der Hütte auf. Die Lichtverhältnisse
        sind perfekt und es werden mehrere Videos aufgenommen.</div>
        <h2>21.09.2021:</h2>
        <div className="mittigblocksatz">Die Videos haben leider nicht ausgereicht. Aus diesem Grund gibt es ein zweites Treffen. Die Jungs tauchen mittags
        auf dem Allahopp auf. Ob der Allahopp schon leer war oder ob die Leute dort aus Respekt und Angst abgereist sind, wissen wir leider nicht. Die Atmosphäre 
        passt perfekt und die restlichen Videos werden geschossen. Song und Video sind fertig, wann wird der Song aber eigentlich released?</div>
        <h2>25.09.2021:</h2>
        <div className="mittigblocksatz">Natürlich auf einer weiteren Gartenparty. Doch diesmal bleibt es nicht beim Songrelease auf Youtube, die beiden Rapper
        wurden auch für ein Livekonzert gebucht. Während es langsam dunkel wird steigt die Aufregung. Oder eigentlich auch nicht, die Jungs haben sich schon gut vollgesoffen.
        Als die Anlage mit dem Mikrofon verbunden war und alle Fans platzgenommen haben konnte es endlich losgehen. Der Song kam bestens an und wurde perfekt durch
        eine Feuerwerksshow abgerundet. Danke nochmals an die gelernten Pyrotechniker, die diese Show ermöglicht haben.  Anschließend standen die zwei Rapstars für gemeinsame Bilder 
        und Autogramme zur Verfügung. Die anwesenden Fans waren begeistert und Autogramme schmückten diverse Körperteile der „Gässeläcker Musik“ - Anhänger. Bilder für die Ewigkeit.</div>
        <h2>27.11.2021:</h2>
        <div className="mittigblocksatz">Nach dem erfolgreichen Sommer, war erstmal Pause angesagt. Da aber bald Weihnachten anstand, haben sich die Jungs
        etwas besonders überlegt. Als Weihnachtsgeschenk wollten sie die "FCG Weihnachtsgeschichte" erzählen. Da die beiden seit 5Uhr morgens im Bus richtung Schalke saßen, 
        hatten sie genug Zeit. Der Text war noch am selben Tag fertig und war einfach perfekt.</div>
        <h2>20.12.2021:</h2>
        <div className="mittigblocksatz">Im Studio wurde der Song jedoch erst kurz vor knapp aufgenommen. Die Jungs schafften es trotzdem rechtzeitig und
        konnten so ihr Geschenk für den 24.12. ankündigen.</div>
        <h2>24.12.2021:</h2>
        <div className="mittigblocksatz">Um 0 Uhr war es soweit. Mit dem Release des dritten Songs wurde ein gelungenes Jahr erfolgreich abgeschlossen. 
        Die Releaseparty wurde durch das Naschen von zahlreichen Milchbrötchen abgerundet.</div>
        <h2>04.11.2022:</h2>
        <div className="mittigblocksatz">Lange hat man nichts gehört von Gässeläcker Musik. Dafür kam es am 04.11.2022 um 23:26 richtig fett. Das Label kann ihr
        erstes Signing seit der Gründung bekannt geben. Für eine Gebühr von 4 Pfandflaschen dürfen wir den neuen Künstler Trapking bei uns im Label begrüßen.
        "Trapking war schon länger mit mir in Kontakt. Als ich am Abend aber seinen Part für einen Song gelesen habe, wusste ich direkt wir brauchen ihn im Label"-Messe687.
        Durch diese Aussage haben viele Fans schon einen gemeinsamen Song der beiden erwartet. Da YoungPfand gerade für 4 Monate ins Ausland flüchten musste,
        konnte er leider keinen Part zu den nächsten Songs beitragen.</div>
        <h2>11.12.2022:</h2>
        <div className="mittigblocksatz">Da Trapking ein eigenes Studio mit ins Label brachte, war er nicht nur auf Rapebene ein Gewinn für uns. Die erste
        richtige Studiosession startete. Der Song "Einfach Weiterfliegen" konnte in bester Qualität aufgenommen werden.</div>
        <h2>03.01.2023:</h2>
        <div className="mittigblocksatz">Nach einer anstrengenden Silvesterfeier hieß es an diesem Tag wieder Studio. Eigentlich hatten die Rapper Trapking und
        Messe687 nur den Feinschliff für "Einfach Weiterfliegen" geplant, doch es kam alles anders. Als Messe687 pünktlich um 14 Uhr am Studio aufgetaucht ist,
        beichtete Trapking ihm, dass er schon seit morgens da ist und einen ganzen Solosong aufgenommen hat. Kaum zu glauben, aber der Song "Was ich so mache" war von Vorne bis Hinten
        einfach perfekt. Da Messe687 auch schon einen fertigen Text für einen Solosong hatte, war er jetzt an der Reihe. Keine vier Stunden später war der Song
        "Wahrheit oder Theorie" aufgenommen und musste nurnoch gemischt werden. Der Tag war aber noch lange nicht vorbei. Nach einer kurzen Essenspause traf
        man sich um 22 Uhr erneut im Studio. Da es schon spät geworden ist, wurde die Stimmung ziemlich deep. Also ein guter Beat gefunden wurde, began das Schreiben und
        um 3 Uhr nachts war der Song "Keine Gefühle" fertig. Nach so einer langen Pause drei Songs an einem Tag. Die Jungs zeigen, dass sie bereit für ein Album sind.</div>
        <h2>08.01.2023:</h2>
        <div className="mittigblocksatz">Da für den nächsten Song noch kein Cover zur Verfügung stand, zog am besagtem Tag eine Gruppe von sieben Leuten los, um sich darum
        zu kümmern. Ganz nach der Line "Sieben Mann im Benz" stand um 1 Uhr die Abfahrt richtung Heidelberg an. Die Gruppe trennte sich in einen Teil, der nur feiern wollte, und 
        einen Teil, der das Cover erstellte. Der passende Platz wurde schon Tage davor gespotted, deshalb dauerte es nicht lange, bis das perfekte Bild geschossen
        wurde. Belohnt wurden die Jungs auch noch. Seit Silvester war anscheinend niemand in der Unterführung und aus diesem Grund lag noch der ganze Pfand im Wert von
        vier Euro vor Ort. Gelungener Abend.</div>
        <h2>14.01.2023:</h2>
        <div className="mittigblocksatz">Der nächste Song wird released. Bei einer illegalen Pokerrunde sollte um 0 Uhr das Highlight des Abends anstehen.
        Nichtnur der Geburtstag eines Rundenmitgliedes sondern auch den Release des Songs "Einfach Weiterfliegen". Trotz einiger Disses wurde der Song gut
        von der Runde aufgenommen. Man hat gemerkt, dass der Song in einem hochklassigen Studio aufgenommen wurde.</div>
        <h2>20.01.2023:</h2>
        <div className="mittigblocksatz">Die Jungs haben Feuer gefangen und ziehen richtig durch. Da der Song "Was ich so mache" von Trapking mit Musikvideo
        erscheinen soll, wurde am besagten Tag aufgenommen. Bei eisiger Kälte und Schneesturm zogen 7 Jungs los zur Tischtennisplatte. Am Ende hat es sich gelohnt.
        Die Aufnahmen waren im Kasten und müssen nurnoch zusammengeschnitten werden. Der nächste Song ist bereit für den Release.</div>
        </>
    );
}