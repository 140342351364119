export const songMap = {
    "DieFcgWeihnachtsgeschichte": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/DieFcgWeihnachtsgeschichte"),
    "EinfachWeiterfliegen": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/EinfachWeiterfliegen"),
    "WasIchSoMache": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/WasIchSoMache"),
    "Comeback": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Comeback"),
    "Kamel": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Kamel"),
    "Alschergarten": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Alschergarten"),
    "Sommerhit": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Sommerhit"),
    "WahrheitOderTheorie": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/WahrheitOderTheorie"),
    "KeineGefuehle": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/KeineGefuehle"),
    "Dropshipping": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Dropshipping"),
    "BesuchBeimBarber": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/BesuchBeimBarber"),
    "Baller": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Baller"),
    "ImmerFlach": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/ImmerFlach"),
    "IchLiebeEs": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/IchLiebeEs"),
    "Duschbier": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Duschbier"),
    "TrapkingIntro": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/TrapkingIntro"),
    "Rage": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Rage"),
    "GibMirDenGin": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/GibMirDenGin"),
    "KeinerVerzeiht": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/KeinerVerzeiht"),
    "EinsamSeitLetzterNacht": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/EinsamSeitLetzterNacht"),
    "NurNochEineStation": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/NurNochEineStation"),
    "Parkanlage": () => import("C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Songs/Parkanlage")
};