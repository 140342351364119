import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/SongsLstTemp/SongsAdv.css"
import GutenMorgenIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/GutenMorgenIMG.jpg"
import ComebackIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/ComebackIMG.jpg"
import EinfachWeiterfliegenIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/EinfachWeiterfliegenIMG.jpg"
import WasIchSoMacheIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/WasIchSoMacheIMG.jpg"

export default function GutenMorgen(){
    return(<>
    <h1>Guten Morgen</h1>
    <a className="image-button-left" href="/Songs/Comeback"><img src={ ComebackIMG } alt="IMG Button Comeback"></img>
    <figcaption className="Bildunterschrift">Comeback - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-center" href="/Songs/Kamel"><img src={ GutenMorgenIMG } alt="IMG Button Kamel"></img>
    <figcaption className="Bildunterschrift">Kamel - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/Alschergarten"><img src={ GutenMorgenIMG } alt="IMG Button Alschergarten"></img>
    <figcaption className="Bildunterschrift">Alschergarten - Trapking</figcaption></a>
    <a className="image-button-left" href="/Songs/Sommerhit"><img src={ GutenMorgenIMG } alt="IMG Button Sommerhit"></img>
    <figcaption className="Bildunterschrift">Sommerhit - Messe687 & Trapking & Stetter</figcaption></a>
    <a className="image-button-center" href="/Songs/WasIchSoMache"><img src={ WasIchSoMacheIMG } alt="IMG Button Was ich so mache"></img>
    <figcaption className="Bildunterschrift">Was ich so mache - Trapking</figcaption></a>
    <a className="image-button-right" href="/Songs/WahrheitOderTheorie"><img src={ GutenMorgenIMG } alt="IMG Button Wahrheit oder Theorie"></img>
    <figcaption className="Bildunterschrift">Wahrheit oder Theorie - Messe687</figcaption></a>
    <a className="image-button-left" href="/Songs/KeineGefuehle"><img src={ GutenMorgenIMG } alt="IMG Button Keine Gefühle"></img>
    <figcaption className="Bildunterschrift">Keine Gefühle - Messe687 & Trapking</figcaption></a>
    <a className="image-button-center" href="/Songs/EinfachWeiterfliegen"><img src={ EinfachWeiterfliegenIMG } alt="IMG Button Einfach Weiterfliegen"></img>
    <figcaption className="Bildunterschrift">Einfach Weiterfliegen - Messe687 & Trapking</figcaption></a>
    <a className="image-button-right" href="/Songs/BesuchBeimBarber"><img src={ GutenMorgenIMG } alt="IMG Button Besuch beim Barber"></img>
    <figcaption className="Bildunterschrift">Besuch beim Barber - Messe687 & Trapking</figcaption></a>
    <a className="image-button-left" href="/Songs/Baller"><img src={ GutenMorgenIMG } alt="IMG Button Baller"></img>
    <figcaption className="Bildunterschrift">Baller - Messe687 & YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/Dropshipping"><img src={ GutenMorgenIMG } alt="IMG Button Dropshipping"></img>
    <figcaption className="Bildunterschrift">Dropshipping - Messe687</figcaption></a>
    </>)
}