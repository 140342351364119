import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/SongsLstTemp/SongsAdv.css"
import GutenMorgenIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/GutenMorgenIMG.jpg"
import TrapkingEpIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/TrapkingEpIMG.jpg"
import SinglesIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/SinglesIMG.jpg"
import AlleSongsIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/AlleSongsIMG.jpg"


export default function SongsAdv(){
    return(<>
    <h1>Alben</h1>
    <a className="image-button-left" href="./SongsAdv/GutenMorgen"><img src={ GutenMorgenIMG } alt="IMG Button Guten Morgen"></img>
    <figcaption className="Bildunterschrift">Guten Morgen - 23.05.2023</figcaption></a>
    <a className="image-button-right" href="./SongsAdv/TrapkingEp"><img src={TrapkingEpIMG } alt="IMG Button TRAPKING EP"></img>
    <figcaption className="Bildunterschrift">TRAPKING EP - 07.05.2024</figcaption></a>
    <h1>Songs</h1>
    <a className="image-button-left" href="./SongsAdv/Singles"><img src={ SinglesIMG } alt="IMG Button Singles"></img>
    <figcaption className="Bildunterschrift">Singles</figcaption></a>
    <a className="image-button-right" href="./SongsAdv/AlleSongs"><img src={AlleSongsIMG } alt="IMG Button AlleSongs"></img>
    <figcaption className="Bildunterschrift">Alle Songs</figcaption></a>
    </>)
}