import "./styles.css"


export default function Navbar(){
    return <nav className="nav">
        <a href="/" className="site-title">Start</a>
        <ul>
            <li>
                <a href="/News">News</a>
            </li>
            <li>
                <a href="/SongsAdv">Songs</a>
            </li>
            <li>
                <a href="/Artists">Artists</a>
            </li>
            <li>
                <a href="/History">History</a>
            </li>
            <li>
                <a href="/About">About</a>
            </li>
        </ul>
    </nav>
}

