import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/SongsLstTemp/SongsAdv.css"
import DieFcgWeihnachtsgeschichteIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/DieFcgWeihnachtsgeschichteIMG.jpg"
import ImmerFlachIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/SinglesIMG.jpg"
import IchLiebeEsIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/IchLiebeEsIMG.jpg"
import NurNochEineStationIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/NurNochEineStationIMG.jpg"
import ParkanlageIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/ParkanlageIMG.jpg"

export default function Singles(){
    return(<>
    <h1>Singles</h1>
    <a className="image-button-left" href="/Songs/Parkanlage"><img src={ ParkanlageIMG } alt="IMG Button Parkanlage"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Parkanlage - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-center" href="/Songs/NurNochEineStation"><img src={ NurNochEineStationIMG } alt="IMG Button Nur noch eine Station"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Nur noch eine Station - YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/IchLiebeEs"><img src={ IchLiebeEsIMG } alt="IMG Button Ich liebe es"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Ich liebe es - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-left" href="/Songs/ImmerFlach"><img src={ ImmerFlachIMG } alt="IMG Button Immer flach"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Immer flach - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/DieFcgWeihnachtsgeschichte"><img src={ DieFcgWeihnachtsgeschichteIMG } alt="IMG Button Die FCG Weihnachtsgeschichte"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Die FCG Weihnachtsgeschichte - Messe687 & YoungPfand</figcaption></a>
    </>)
}