
export default function Artists(){
    return (<>
        <h1>Artists</h1>
        <div className="linksmitte">
        <ul className="großeliste">
            <li><a href="./Artists/Messe687">Messe687</a></li>
            <li><a href="./Artists/Trapking">Trapking</a></li>
            <li><a href="./Artists/YoungPfand">YoungPfand</a></li>
        </ul>
        </div>
        </>);
}