import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/SongsLstTemp/SongsAdv.css"
import TrapkingEpIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/TrapkingEpIMG.jpg"
import DuschbierIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/DuschbierIMG.jpg"

export default function TrapkingEp(){
    return(<>
        <h1>TRAPKING EP</h1>
        <a className="image-button-left" href="/Songs/TrapkingIntro"><img src={ TrapkingEpIMG } alt="IMG Button Trapking Intro"></img>
        <figcaption className="Bildunterschrift">Trapking(Intro) - Trapking</figcaption></a>
        <a className="image-button-center" href="/Songs/Rage"><img src={ TrapkingEpIMG } alt="IMG Button Rage"></img>
        <figcaption className="Bildunterschrift">Rage - Trapking</figcaption></a>
        <a className="image-button-right" href="/Songs/GibMirDenGin"><img src={ TrapkingEpIMG } alt="IMG Button Gib mir den Gin"></img>
        <figcaption className="Bildunterschrift">Gib mir den Gin - Trapking</figcaption></a>
        <a className="image-button-left" href="/Songs/Duschbier"><img src={ DuschbierIMG } alt="IMG Button Duschbier"></img>
        <figcaption className="Bildunterschrift">Duschbier - Trapking & Luc</figcaption></a>
        <a className="image-button-center" href="/Songs/KeinerVerzeiht"><img src={ TrapkingEpIMG } alt="IMG Button Keiner verzeiht"></img>
        <figcaption className="Bildunterschrift">Keiner verzeiht - Trapking</figcaption></a>
        <a className="image-button-right" href="/Songs/EinsamSeitLetzterNacht"><img src={ TrapkingEpIMG } alt="IMG Button Einsam seit letzter Nacht"></img>
        <figcaption className="Bildunterschrift">Einsam seit letzter Nacht - Trapking</figcaption></a>
        </>)
}