export default function News(){
    return (<>
    <h1>News</h1>
        <h2>07.05.2024:</h2>
        <div className="mittigblocksatz">Der King ist zurück! Die Trapking EP ist draußen und hat komplett abgerissen!</div>
        <h2>12.04.2024:</h2>
        <div className="mittigblocksatz">Duschbier ist nach langem Warten endlich da! Alle streamen!</div>
        <h2>01.08.2023:</h2>
        <div className="mittigblocksatz">Der erste Song nach dem Album wird demnächst auf Spotify veröffentlicht.</div>
        <h2>23.05.2023:</h2>
        <div className="mittigblocksatz">Heute ist unser Album "Guten Morgen" auf Spotify erschienen.</div>
        <h2>07.04.2023:</h2>
        <div className="mittigblocksatz">Am 15.04.2023 23:59 Uhr kommt der neue Track "Comeback". Schreibt euch dieses historische Datum in den Kalender.
        Alles Mitglieder werden vertreten sein!</div>
        <h2>25.02.2023:</h2>
        <div className="mittigblocksatz">Am 04.03.2023 23:59 Uhr kommt der neue Track "Was ich so mache" von Trapking. Unterstützt den Jungen und seid alle bei der
        Premiere auf Youtube dabei.</div>
        <h2>25.01.2023:</h2>
        <div className="mittigblocksatz">Am 20.05.2023 sind wir live im Alschergarten. Außerdem wird dort unser erstes Album gedroppt.</div>
        <h2>07.01.2023:</h2>
        <div className="mittigblocksatz">Am 15.01.2023 0 Uhr kommt der neue Track "Einfach Weiterfliegen" von Messe687 & Trapking. Seid alle bei der
        Premiere auf Youtube dabei.</div>
        
    </>);
}