import "./pages.css"
import Sprayer from "./GässeläckerMusik.png"

export default function Start() {
    return (<>
        <h1>Herzlich Willkommen auf der offiziellen Seite von Gässeläcker Musik</h1>
        <h3 className="onlymobile">Für eine bessere Erfahrung empfehlen wir Ihnen die Seite auf einem PC oder Laptop aufzurufen. Die Seite ist zwar auch für Ihr Handy oder Tablet optimiert, hat jedoch weniger coole Features.</h3>
        <div className="Fließtext">
        Willkommen bei Gässeläcker Musik, dem Heimatlabel für einige der talentiertesten und innovativsten Rapper in der Branche. <br/>
        Wir glauben an die Macht der Musik, um Menschen zusammenzubringen und ihnen die Möglichkeit zu geben, ihre Stimmen zu erheben. Seit unserer Gründung 2019 haben wir uns darauf konzentriert, Künstler zu unterstützen, die authentisch und leidenschaftlich über ihre Erfahrungen und Perspektiven rappen. <br/>
        Unser Label umfasst eine Vielfalt an Künstlern, von denen jeder eine einzigartige Stimme und Geschichte hat. Wir arbeiten eng mit ihnen zusammen, um ihre Musik zu produzieren und ihnen beim Wachstum ihrer Karriere zu helfen. <br/>
        Wir sind stolz darauf, eine Plattform für diese talentierten Künstler zu sein und freuen uns darauf, ihre Musik mit der Welt zu teilen. Schauen Sie sich unsere Künstlerseite an, um mehr über sie zu erfahren und ihre Musik zu hören.<br/>    
        Danke, dass Sie uns besuchen und unterstützen. Wir freuen uns auf die Zukunft und darauf, mehr großartige Musik mit Ihnen zu teilen.<br/>
        <figure>
            <img src={Sprayer} alt="Am Arbeiten" className="full-width-image"></img>
            <figcaption className="Bildunterschrift">07.01.2023, letzte Vorbereitungen für den Songrelease</figcaption>
        </figure>
        </div>
        </>
    );
} 