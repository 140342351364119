import './App.css';
import Song from './SongsLstTemp/SongsTemplate.js';
import Start from './Start.js'
import Navbar from './Navbar.js'
import About from './About.js';
import Artists from './Artists.js';
import Messe687 from './Artists/Messe687.js';
import Trapking from './Artists/Trapking.js';
import YoungPfand from './Artists/YoungPfand.js';
import News from './News.js';
import "./styles.css"
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import History from './History.js'
//import Songs from './SongsLstTemp/Songs.js';
import SongsAdv from './SongsLstTemp/SongsAdv.js';
import GutenMorgen from './Albums/GutenMorgen.js';
import TrapkingEp from './Albums/TrapkingEp.js';
import Singles from './Albums/Singles.js';
import AlleSongs from './Albums/AlleSongs.js';

function App() {
  return (
      <Router>
          <>
              <Navbar />
              <div className="container">
                  <Routes>
                        <Route path="/" element={<Start />} />
                        <Route path="/Songs/:songName" element={<SongWrapper />} />
                        <Route path="/About" element={<About />} />
                        <Route path="/Artists" element={<Artists />} />
                        <Route path="/Artists/Messe687" element={<Messe687 />} />
                        <Route path="/Artists/Trapking" element={<Trapking />} />
                        <Route path="/Artists/YoungPfand" element={<YoungPfand />} />
                        <Route path="/SongsAdv" element={<SongsAdv />} />
                        <Route path="/SongsAdv/GutenMorgen" element={<GutenMorgen/>} />
                        <Route path="/SongsAdv/TrapkingEp" element={<TrapkingEp/>} />
                        <Route path="/SongsAdv/Singles" element={<Singles/>} />
                        <Route path="/SongsAdv/AlleSongs" element={<AlleSongs/>} />
                        <Route path="/News" element={<News />} />
                        <Route path="/History" element={<History />} />
                        <Route path="*" element={<Start />} />
                  </Routes>
              </div>
          </>
      </Router>
  );
}

function SongWrapper({ match }) {
  const { songName } = useParams();
  return <Song songName={songName} />;
}

export default App;
