import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/pages.css"
export default function About(){
    return (<>
        <h1>About</h1>
        <h2>Hier findet ihr alle Kontaktinformationen</h2>
        <div>
            <h3>Schreibt uns gerne eine Mail, dafür einfach in der Liste euren Grund anklicken.</h3>
            <ul className="mail">
                <li><a href="mailto:gaesselaeckermusik@gmail.com?subject=Verbesserungsvorschläge">Verbesserungsvorschläge(Website)</a></li>
                <li><a href="mailto:gaesselaeckermusik@gmail.com?subject=Feature">Feature</a></li>
                <li><a href="mailto:gaesselaeckermusik@gmail.com?subject=Auftritte">Auftritte</a></li>
                <li><a href="mailto:gaesselaeckermusik@gmail.com?subject=Sonstiges">Sonstiges</a></li>
            </ul>
        </div>
        <div>
            <a className="youtube" href="https://www.youtube.com/@gasselackermusik">Hier findet ihr uns auf Youtube</a>
            <a className="spotifyabout" href="https://open.spotify.com/user/31qmvv7otabk77tj6fcjjwlcbuii?si=30701793c7444959">Hier findet ihr uns auf Spotify</a>
        </div>

    </>);
}