import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/SongsLstTemp/SongsAdv.css"
import TrapkingEpIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/TrapkingEpIMG.jpg"
import DuschbierIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/DuschbierIMG.jpg"
import DieFcgWeihnachtsgeschichteIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/DieFcgWeihnachtsgeschichteIMG.jpg"
import ImmerFlachIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/SinglesIMG.jpg"
import IchLiebeEsIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/IchLiebeEsIMG.jpg"
import NurNochEineStationIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/NurNochEineStationIMG.jpg"
import GutenMorgenIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/GutenMorgenIMG.jpg"
import ComebackIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/ComebackIMG.jpg"
import EinfachWeiterfliegenIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/EinfachWeiterfliegenIMG.jpg"
import WasIchSoMacheIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/WasIchSoMacheIMG.jpg"
import ParkanlageIMG from "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Cover/ParkanlageIMG.jpg"

export default function AlleSongs(){
    return(<>
    <h1>Alle Songs</h1>´
    <a className="image-button-left" href="/Songs/Parkanlage"><img src={ ParkanlageIMG } alt="IMG Button Parkanlage"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Parkanlage - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-center" href="/Songs/TrapkingIntro"><img src={ TrapkingEpIMG } alt="IMG Button Trapking Intro"></img>
    <figcaption className="Bildunterschrift">Trapking(Intro) - Trapking</figcaption></a>
    <a className="image-button-right" href="/Songs/Rage"><img src={ TrapkingEpIMG } alt="IMG Button Rage"></img>
    <figcaption className="Bildunterschrift">Rage - Trapking</figcaption></a>
    <a className="image-button-left" href="/Songs/GibMirDenGin"><img src={ TrapkingEpIMG } alt="IMG Button Gib mir den Gin"></img>
    <figcaption className="Bildunterschrift">Gib mir den Gin - Trapking</figcaption></a>
    <a className="image-button-center" href="/Songs/Duschbier"><img src={ DuschbierIMG } alt="IMG Button Duschbier"></img>
    <figcaption className="Bildunterschrift">Duschbier - Trapking & Luc</figcaption></a>
    <a className="image-button-right" href="/Songs/KeinerVerzeiht"><img src={ TrapkingEpIMG } alt="IMG Button Keiner verzeiht"></img>
    <figcaption className="Bildunterschrift">Keiner verzeiht - Trapking</figcaption></a>
    <a className="image-button-left" href="/Songs/EinsamSeitLetzterNacht"><img src={ TrapkingEpIMG } alt="IMG Button Einsam seit letzter Nacht"></img>
    <figcaption className="Bildunterschrift">Einsam seit letzter Nacht - Trapking</figcaption></a>
    <a className="image-button-center" href="/Songs/NurNochEineStation"><img src={ NurNochEineStationIMG } alt="IMG Button Nur noch eine Station"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Nur noch eine Station - YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/IchLiebeEs"><img src={ IchLiebeEsIMG } alt="IMG Button Ich liebe es"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Ich liebe es - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-left" href="/Songs/ImmerFlach"><img src={ ImmerFlachIMG } alt="IMG Button Immer flach"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Immer flach - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-center" href="/Songs/Comeback"><img src={ ComebackIMG } alt="IMG Button Comeback"></img>
    <figcaption className="Bildunterschrift">Comeback - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/Kamel"><img src={ GutenMorgenIMG } alt="IMG Button Kamel"></img>
    <figcaption className="Bildunterschrift">Kamel - Messe687 & Trapking & YoungPfand</figcaption></a>
    <a className="image-button-left" href="/Songs/Alschergarten"><img src={ GutenMorgenIMG } alt="IMG Button Alschergarten"></img>
    <figcaption className="Bildunterschrift">Alschergarten - Trapking</figcaption></a>
    <a className="image-button-center" href="/Songs/Sommerhit"><img src={ GutenMorgenIMG } alt="IMG Button Sommerhit"></img>
    <figcaption className="Bildunterschrift">Sommerhit - Messe687 & Trapking & Stetter</figcaption></a>
    <a className="image-button-right" href="/Songs/WasIchSoMache"><img src={ WasIchSoMacheIMG } alt="IMG Button Was ich so mache"></img>
    <figcaption className="Bildunterschrift">Was ich so mache - Trapking</figcaption></a>
    <a className="image-button-left" href="/Songs/WahrheitOderTheorie"><img src={ GutenMorgenIMG } alt="IMG Button Wahrheit oder Theorie"></img>
    <figcaption className="Bildunterschrift">Wahrheit oder Theorie - Messe687</figcaption></a>
    <a className="image-button-center" href="/Songs/KeineGefuehle"><img src={ GutenMorgenIMG } alt="IMG Button Keine Gefühle"></img>
    <figcaption className="Bildunterschrift">Keine Gefühle - Messe687 & Trapking</figcaption></a>
    <a className="image-button-right" href="/Songs/EinfachWeiterfliegen"><img src={ EinfachWeiterfliegenIMG } alt="IMG Button Einfach Weiterfliegen"></img>
    <figcaption className="Bildunterschrift">Einfach Weiterfliegen - Messe687 & Trapking</figcaption></a>
    <a className="image-button-left" href="/Songs/BesuchBeimBarber"><img src={ GutenMorgenIMG } alt="IMG Button Besuch beim Barber"></img>
    <figcaption className="Bildunterschrift">Besuch beim Barber - Messe687 & Trapking</figcaption></a>
    <a className="image-button-center" href="/Songs/Baller"><img src={ GutenMorgenIMG } alt="IMG Button Baller"></img>
    <figcaption className="Bildunterschrift">Baller - Messe687 & YoungPfand</figcaption></a>
    <a className="image-button-right" href="/Songs/Dropshipping"><img src={ GutenMorgenIMG } alt="IMG Button Dropshipping"></img>
    <figcaption className="Bildunterschrift">Dropshipping - Messe687</figcaption></a>
    <a className="image-button-center" href="/Songs/DieFcgWeihnachtsgeschichte"><img src={ DieFcgWeihnachtsgeschichteIMG } alt="IMG Button Die FCG Weihnachtsgeschichte"></img>
    <figcaption className="Bildunterschrift" style={{fontSize: "1.25em" }}>Die FCG Weihnachtsgeschichte - Messe687 & YoungPfand</figcaption></a>
    </>)
}