import React, { useState, useEffect } from 'react';
import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/pages.css"
import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/Button.css"
import { songMap } from './SongMap';

export default function Song({ songName }) {
    const [song, setSong] = useState(null);

    useEffect(() => {
        if (songMap[songName]) {
            songMap[songName]().then(module => {
                setSong(module.song);
            });
        }
    }, [songName]);

    if (!song) {
        return <div>Loading...</div>;
    }


    return (<>
        <h1>{song.title}</h1>
        <iframe className='video'
            title='Youtube Player'
            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
            src={`https://youtube.com/embed/${song.youtubeID}?autoplay=0`}
            allowfullscreen
            frameborder="0"
            id = "youtube">
        </iframe>
        <iframe className="spotify"
            title="Spotify Player"
            src={`https://open.spotify.com/embed/track/${song.spotifyID}?utm_source=generator`}
            frameborder="0"
            allowTransparency="true"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            id = "spotify">
        </iframe>
        <div className="linksmitte">
            <u>Lyrics:</u><br/>
            <pre>{song.lyrics}</pre>
        </div>
        <h4>Downloade "{song.title}" hier:</h4>

        <button><span><a href={song.mp3} download={song.name} target="_blank" rel="noreferrer" className="download">Download</a></span></button>
    </>);
}