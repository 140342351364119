import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/pages.css"

export default function Trapking(){
    return(<>
    <h1>Trapking</h1>
    <div className="mittigblocksatz">Trapking ist ein deutscher Rapper aus Heidelberg. Seit 2022 ist er Mitglied beim Label Gässeläcker Musik.<br/><br/>
    Trapking, dessen Name inspiriert ist von dem gleichnamigen Songtitel seines Idols Ufo361, verbrachte seine Kindheit in Heidelberg und Umgebung. Mittlerweile gibt es keine genauen Angaben mehr, ob er sich nun in Karlsruhe oder Heidelberg aufhält. 
    Er kommt ursprünglich aus der Freestyler Szene und lieferte sich in der Vergangenheit teils epische Rapbattles mit Amateur-Rappern aus seinem Umfeld. Aufnahmen davon sind jedoch von hoher Rarität.
    Schon mit 14 Jahren begann er Musik aufzunehmen und einige Jahre später sollte das erste große Projekt auf die Beine gestellt werden.
    Der „Bubensampler“ wurde jedoch nie fertiggestellt und geriet in Vergessenheit.<br/><br/>
    Stattdessen kam Trapking im Jahr 2022 in Kontakt mit den Label-Bossen Messe687 und YoungPfand. Schon seit deren ersten Releases war große Bewunderung für die Arbeit der Jungs vorhanden  und Trapking sah die Möglichkeit über das Label den nächsten Schritt in seiner noch so jungen Karriere als Rapper zu gehen. Wenig später kam es dann zum Signing.<br/><br/>
    Ansonsten ist nicht viel bekannt über den sich bedeckt haltenden Künstler, dem eine große Zukunft vorhergesagt wird. Durch die Sturmmaske ist seine Identität bis heute unbekannt. Angaben zufolge soll noch in diesem Jahr (2023) ein Album zusammen mit Messe687 und YoungPfand erscheinen.</div>
    </>);
}