import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/pages.css"

export default function Messe687(){
    return(<>
    <h1>Messe687</h1>
    <div className="mittigblocksatz">Messe687 ist ein deutscher Rapper aus Plankstadt, der seine Musik auf seinem eigenen Label Gässeläcker Musik veröffentlicht.<br/><br/>
    In seiner Jugend hielt er meist wenig vom deutschen Hip-Hop. Als sich über die Jahre sein soziales Umfeld änderte, fing auch er an, sich mit Deutschrap zu beschäftigen.
    Mit 17 Jahren schrieb er seine ersten Texte. Das geplante erste Album "Rhein-Neckar-Gemetzel" wurde jedoch nie fertig gestellt. Stattdessen zerstörte er mehrere Karrieren
    durch seine Battlerap-Qualität. Obwohl sein ebenfalls begabter Kollege Trapking kaum 10km entfernt aufwuchs, gab es zwischen beiden nie ein Rapbattle. 
    Die ersten richtigen Texte ergaben sich, als er sich mit seinem Kindheitskollegen YoungPfand zusammentat. Da Messe687 auch eine Selbstausbildung als "Video-Editor"
    genossen hat, musste das Label keine extra Stelle besetzen.<br/><br/>
    Messe687s „Gangsta-Rap“ setzt auf kalkulierte Grenzüberschreitungen, Provokationen und Tabubrüche, die als Teil seiner Marketingstrategie gelten.
    Kontinuierlich stießen bestimmte Aussagen seiner Texte auf Kritik und erzeugten Konflikte um seine Auftritte. Nach eigener Aussage versucht Messe687 in zahlreichen
    Rapliedern auch Ideale wie Zielstrebigkeit, Ausdauer und Geduld sowie körperliche und geistige Fitness für ein „sauberes und erfolgreiches Leben“ zu vermitteln.<br/><br/>
    Diese Art von Rap erinnert sehr an seine Idole. Neben Kollegah, Farid Bang und der 187 Strassenbande prägten ihn auch die österreichischen Rapper Perserka und Mo86,
    welche vor allem durch ihr reales Auftreten im Internet seinen Respekt gewonnen haben. Ob wir jemals ein Feature mit einem dieser Rapper sehen werden,
    können wir bis jetzt nur spekulieren.<br/><br/>
    In Zukunft plant Messe687 ein Album zusammen mit Trapking und YoungPfand. Darauf will er seine Klasse und Meinung noch stärker vertreten. Wird es ein
    Doubletime-Part auf Kollegah-Style geben oder ein Disstrack auf Farid Art? Rausfinden werden es die Fans wohl erst am 20.05.2023.</div>
    </>);
}