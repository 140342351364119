import "C:/Users/User/ReactProjects/bessere_gaesselaeckermusik/src/pages.css"

export default function YoungPfand(){
    return(<>
    <h1>YoungPfand</h1>
    <div className="mittigblocksatz">YoungPfand ist ein deutschsprachiger Rapper, der in Plankstadt aufwuchs.
    Seit 2019 veröffentlicht er seine Werke über sein eigenes Label Gässeläcker Musik. <br/><br/>
    Mit 16 Jahren fing er, inspiriert von seinem Freund Messe687, mit dem Rappen an. Als er durch Kollegen Trapking kennenlernte, 
    hatte er bereits einen Vertrag beim eigens gegründeten Label Gässeläcker Musik 2019 unterschrieben. 
    Durch das erschaffene Pfandflaschen-Imperium lag es auf der Hand „YoungPfand“ als passenden Künstlernamen auf dem deutschen Rapmusik-Markt zu 
    etablieren.<br/><br/>
    YoungPfand ist bekannt dafür, häufig Beef (auf Deutsch: Streit), also in der Regel verbale Streitigkeiten,
    die z.B. durch Beleidigungen („Disses“) auf Platten ausgetragen werden, mit anderen Rappern zu haben. So kam es zu
    verbalen Konflikten mit diversen Konkurrenten aus seinem Umfeld. <br/><br/>
    Durch die haarsträubende Entwicklung in der Deutschrap-Szene, die laut HipHop.de „talentlos wie nie zuvor“ ist, 
    entschied sich YoungPfand im November 2022 für vier Monate ins Ausland zu flüchten. Dort wolle er Kraft tanken und neue 
    Inspiration für seine Raptexte erlangen. Geplant ist ein famoses Comeback mit den Rapbrüdern Messe687 und Trapking im Frühjahr 2023. 
    Die Fans können gespannt sein.</div>
    </>);
}